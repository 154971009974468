import { message } from "antd";
import { PaginationConfig } from "antd/lib/table";
import axios, { CancelToken } from "axios";
import { FilterDataObject, PaginationObject } from "client/components/Filters/Filter";
import { SamplingSubPolicyAttrMappingType } from "client/components/QueryBuilder";
import UserStore from "client/Data/UserStore";
import { ActiveDirectorySettingsClient } from "client/scenes/Home/scenes/AdminSettings/scenes/ActiveDirectorySettings/types";
import * as apiIndependentFunctions from "client/services/apiFunctions";
import {
  CleaningLimitPolicyResidueType,
  CustomReportFieldType,
  CustomReportType,
  EquipmentGroupTables,
  FileType,
  LegacyDocumentType,
  Operator,
  ReferenceDocumentEntityType,
  ReportDocumentType,
  SamplingLocationsContactType,
  SessionStorageKeys
} from "common/enums";
import { FeatureFlagsType, UUIDType } from "common/types";
import { RunFormFieldType } from "common/types/cleaning-inspection-settings";
import moment from "moment";
import queryString from "query-string";
// Mock for Plan -> Release Control
import { SampleObjType } from "client/scenes/Home/scenes/Production/scenes/Equipment/components/EquipmentDetail/components/AddEditSamplesForm";
import { fileReader } from "../utils";
import { HttpClient } from "./http-client";
import { apiPaths } from "./paths";
import { EqAdditionalFieldMapping } from "client/components/WorkFlow/WorkFlows/Production/StartProduction/Components/MapCustomProperties";
import { createLocalDateTimeFormatter } from "common/utils/date-time-format";

const base_path = "/api/v2";

const currentPlan = UserStore.currentPlan;

export const getFacilityPath = (facilityId?: string) => {
  const currentFacility = sessionStorage.getItem(SessionStorageKeys.CURRENT_FACILITY);
  const facility = facilityId ? facilityId : currentFacility;
  return `/plans/${currentPlan}/facilities/${facility}`;
};

const paths = apiPaths;

export function getVersionInfo() {
  return process.env.__VERSION_INFO__;
}

export const getCurrentFacility = async () => {
  const path = base_path + `/plans/${currentPlan}/facilities`;
  const options = HttpClient.requestConfig();
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getFacilities = async (setSessionId: boolean = false) => {
  const path = base_path + `/plans/${currentPlan}/facilities`;
  const options = HttpClient.requestConfig();
  try {
    const res = await HttpClient.get(path, options);
    return (res.data && res.data.facilities) || {};
  } catch (e) {
    if (e.response) matchStatusAndThrowError(e, "Get Facility");
    throw e;
  }
};

function genericGet(pathKey: string, action: string, key: string, id?: number) {
  return async function () {
    let options = HttpClient.requestConfig();
    if (pathKey === "users_path") {
      const acceptHeader = "application/vnd.leucinetech.id.name+json";
      options = { headers: { ...options.headers, Accept: acceptHeader } };
    }
    try {
      let path = paths[pathKey];
      if (id) {
        path = path + "/" + id;
      }
      if (!path) throw new Error(`Path not defined for ${pathKey}`);
      const res = await HttpClient.get(path, options);
      return res.data[key];
    } catch (e) {
      if (e.response) {
        matchStatusAndThrowError(e, action);
      } else throw e;
    }
  };
}

async function genericGetIdAndNameForFacility(
  path: string,
  action: string,
  key: string,
  cancelToken?: CancelToken,
  showArchived?: boolean
) {
  let options = HttpClient.requestConfig();
  const acceptHeader = "application/vnd.leucinetech.id.name+json";
  options = { headers: { ...options.headers, Accept: acceptHeader } };

  if (cancelToken) {
    options["cancelToken"] = cancelToken;
  }

  try {
    const res = await HttpClient.get(path, options);
    return showArchived ? res.data.result : res.data[key];
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

async function genericGetForFacility(
  path: string,
  action: string,
  key: string,
  cancelToken?: CancelToken,
  showArchived?: boolean
) {
  const options = HttpClient.requestConfig();

  if (cancelToken) {
    options["cancelToken"] = cancelToken;
  }

  try {
    const res = await HttpClient.get(path, options);
    return showArchived ? res.data.result : res.data[key];
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export function getUserErrorMessage(error: { [index: string]: any }): string {
  let errorMessage = "";
  switch (error.errName) {
    case "RequestingUserNotfound":
      errorMessage = "Email address/ Employee ID is not recognized";
      break;
    case "UserBlockedError":
      errorMessage = "User is blocked. Please contact your administrator";
      break;
    case "LoginAttemptsExceeded":
      errorMessage = error.message;
      break;
    case "InvalidEmailOrPasswordError":
      errorMessage = error.message;
      break;
    case "ChangePasswordOnFirstLogin":
      errorMessage =
        "You are logging in for the first time, you will be redirected to change your password shortly";
      break;
    case "ChangePasswordOnPasswordExpiry":
      errorMessage =
        "Your password has been expired, you will be redirected to change your password shortly";
      break;
    case "PasswordAlreadyUsed":
      errorMessage =
        "The password you entered was previously used, please use a different password for better security";
      break;
    case "ChangePasswordOnPasswordExpiryForEmployeeIdError":
      errorMessage = error.message;
      break;
    case "InvalidToken":
      errorMessage = "The secret key you entered is invalid. Please contact your administrator";
  }
  return errorMessage;
}

let sessionExpiredCount = 0;

export function matchStatusAndThrowError(http_error: any, action: string) {
  const status = http_error.response.status;

  switch (status) {
    case 419:
      UserStore.isUserLoggedIn = false;
      if (sessionExpiredCount < 1) {
        sessionExpiredCount += 1;
        message.config({
          maxCount: 1
        });
        message.warning("Logged Out. You are logged in on a different device!");
      }
      throw {
        ...http_error,
        message: "Logged Out. You are logged in on a different device!"
      };
    case 409:
    case 400:
    case 422:
      throw http_error.response.data.error;

    case 401:
      message.config({
        maxCount: 1
      });
      message.warning("Session Expired. Login again!");
      UserStore.isUserLoggedIn = false;
    case 403:
      throw new NotAllowed(action, http_error.response.data.error);
    case 404: {
      if (
        http_error.response.data.error &&
        http_error.response.data.error.name === "NotFoundError"
      ) {
        throw new NotFound(action);
      } else {
        throw new APIUnreachable(action);
      }
    }
    case 500:
      throw new ServerError(action);
    default:
      throw new UnrecognizedError(action);
  }
}

export class ExtendableError extends Error {
  constructor(errorMessage: string) {
    super(errorMessage);
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = new Error(errorMessage).stack;
    }
  }
}

export class APIUnreachable extends ExtendableError {
  action: string;
  constructor(action: string) {
    const errorMessage = `${action} failed as server unreachable.`;
    super(errorMessage);
    this.action = action;
  }
}

export class UnrecognizedError extends ExtendableError {
  action: string;
  constructor(action: string) {
    super(`${action} failed due to uncategorized error.`);
    this.action = action;
  }
}

export class NotAllowed extends ExtendableError {
  action: string;
  error: any;
  constructor(action: string, error?: any) {
    super(`${action} failed as current user is not allowed.`);
    this.action = action;
    this.error = error;
  }
}

export class NotFound extends ExtendableError {
  action: string;
  constructor(action: string) {
    super(`${action} failed as resource not found.`);
    this.action = action;
  }
}

export class ServerError extends ExtendableError {
  action: string;
  constructor(action: string) {
    super(`${action} failed due to server error.`);
    this.action = action;
  }
}

export async function registerUser(userId: string, secretKey: string) {
  try {
    const setPasswordLinkObj = await HttpClient.post(paths.register_user_path, {
      username: userId,
      token: secretKey
    });
    return setPasswordLinkObj.data.link;
  } catch (e) {
    if (e.response) {
      const action = "Register User";
      const errorObject = e.response && e.response.data && e.response.data.error;
      if (errorObject && apiIndependentFunctions.isUserError(errorObject)) {
        throw errorObject;
      } else {
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
}

export async function loginUser(userId: string, password?: string) {
  try {
    const res = await HttpClient.post(paths.login_path, {
      username: userId,
      password
    });
    sessionStorage.setItem(SessionStorageKeys.USER_ID, userId);
    sessionStorage.setItem(SessionStorageKeys.TOKEN, res.data.token);
    axios.defaults.headers.common["token"] = sessionStorage.getItem(SessionStorageKeys.TOKEN);
    return (res.data && res.data.license) || [];
  } catch (e) {
    if (e.response) {
      const action = "Login";
      const errorObject = e.response && e.response.data && e.response.data.error;
      if (errorObject && apiIndependentFunctions.isUserError(errorObject)) {
        throw errorObject;
      } else {
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
}

export async function logoutUser(ssoEnabled: boolean = false, logoutUrl?: string) {
  const options = HttpClient.requestConfig();
  let status;
  try {
    if (ssoEnabled && logoutUrl) {
      logoutUrl += sessionStorage.getItem(SessionStorageKeys.TOKEN);
      window.location.href = logoutUrl;
    } else {
      const res = await HttpClient.post(paths.logout_path, options);
      status = res.status;
    }
  } catch (e) {
    if (e.response) {
      const action = "Logout";
      matchStatusAndThrowError(e, action);
      status = e.response.status;
    } else throw e;
  }
  return status;
}

export async function forgotPassword(
  username: string,
  token: string | undefined,
  verifyToken: boolean
) {
  try {
    const res = (await HttpClient.post(paths.forgot_pass_path, {
      username,
      token,
      verifyToken
    })) as any;

    if (res.isEmail) {
      return { status: res.status, isEmail: res.isEmail };
    } else {
      return res;
    }
  } catch (e) {
    if (e.response) {
      const action = "Recovering password";
      const errorObject = e.response && e.response.data && e.response.data.error;
      if (errorObject && apiIndependentFunctions.isUserError(errorObject)) {
        throw errorObject;
      } else {
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
}
export async function resetPasswordPolicy(email: string, token: string) {
  try {
    const res = await HttpClient.get(
      `${paths.reset_password_policy_path}?${queryString.stringify({
        token,
        u: email
      })}`
    );
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Fetching Reset Password Policy";
      const errorObject = e.response && e.response.data && e.response.data.error;
      if (errorObject && apiIndependentFunctions.isUserError(errorObject)) {
        throw errorObject;
      } else {
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
  return status;
}
export async function changePassword(
  username: string,
  newPassword: string,
  confirmPassword: string,
  tokenQuery: string | undefined,
  existingPassword: string | undefined
) {
  let status;
  let postObject: any = { username, newPassword, confirmPassword };
  if (existingPassword) {
    postObject = { ...postObject, existingPassword };
  }

  const path = tokenQuery ? `${paths.change_pass_path}?${tokenQuery}` : paths.change_pass_path;

  try {
    const res = await HttpClient.post(path, postObject);
    status = res.status;
  } catch (e) {
    if (e.response) {
      const action = "Changing password";
      const errorObject = e.response && e.response.data && e.response.data.error;
      if (errorObject && apiIndependentFunctions.isUserError(errorObject)) {
        throw errorObject;
      } else {
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
  return status;
}

export async function getCurrentUser() {
  const options = HttpClient.requestConfig();

  try {
    const res = await HttpClient.get(`${paths.users_path}/me`, options);
    return res.data.users;
  } catch (e) {
    if (e.response) {
      const action = "Getting current user";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export const generateDocuments = async (
  productionId: string,
  documentType: string,
  fileName: string
) => {
  const macLimitReportLink =
    base_path + getFacilityPath() + "/reports/" + documentType + "/" + productionId + "/pdf";
  const options = HttpClient.requestConfig();
  try {
    const res = (await HttpClient.get(macLimitReportLink, {
      ...options,
      responseType: "blob"
    })) as any;
    const blob = new Blob([res.data], {
      type: "application/pdf"
    });
    const reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      const action = "Getting PDE report";
      matchStatusAndThrowError(e, action);
    } else throw e;
    return;
  }
};

export const getMasterImportFile = async () => {
  const path = base_path + getFacilityPath() + "/master";
  const options = HttpClient.requestConfig();
  try {
    const res = (await HttpClient.get(path, {
      ...options,
      responseType: "blob"
    })) as any;
    const blob = new Blob([res.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    });
    const reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    /**
     * Windows unable to extract extension from the MIME type,
     * so hardcoding the file extension as xlsx
     */
    link.setAttribute("download", `Production Data.xlsx`);
    document.body.appendChild(link);
    link.click();
    return res;
  } catch (e) {
    if (e.response) {
      const action = "Getting master import file";
      matchStatusAndThrowError(e, action);
    } else throw e;
    return;
  }
};

export async function uploadProductionMigrationFile(formData: { [index: string]: any }) {
  const path = base_path + getFacilityPath() + "/master";
  const options = HttpClient.requestConfig();
  try {
    const res = await HttpClient.post(path, formData, options);
    return (res.data && res.data.result) || {};
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "");
    } else throw e;
    return;
  }
}

export const generateReport = generateReportApi("report_generation_path", "Generating Protocol");

export async function getDefaultUnits(cancelToken?: CancelToken, showArchived?: boolean) {
  let path = base_path + getFacilityPath() + "/default_units";
  if (showArchived) {
    path = base_path + getFacilityPath() + "/archived/default_units";
  }
  return genericGetForFacility(
    path,
    "Getting Variables",
    "default_units",
    cancelToken && cancelToken,
    showArchived
  );
}

export async function getRPNCategories(cancelToken?: CancelToken, showArchived?: boolean) {
  const path = base_path + getFacilityPath() + "/rpn_categories";
  return genericGetForFacility(
    path,
    "Getting rpn categories",
    "rpn_categories",
    cancelToken && cancelToken,
    showArchived
  );
}
export async function getRPNFormulas(cancelToken?: CancelToken, showArchived?: boolean) {
  const path = base_path + getFacilityPath() + "/rpn_formulas";
  return genericGetForFacility(
    path,
    "Getting rpn formulas",
    "rpn_formulas",
    cancelToken && cancelToken,
    showArchived
  );
}

export async function getVariables(cancelToken?: CancelToken, showArchived?: boolean) {
  let path = base_path + getFacilityPath() + "/variables";
  if (showArchived) {
    path = base_path + getFacilityPath() + "/archived/variables";
  }
  return genericGetForFacility(
    path,
    "Getting Variables",
    "variables",
    cancelToken && cancelToken,
    showArchived
  );
}
export const getFormulas = genericGet("formula_path", "Getting formulas", "formulas");

export const getAuditLogs = genericGet("log_path", "Getting audit logs", "audit_logs");

export const getAnalyticalMethods = genericGet(
  "analytical_method_path",
  "Getting Analytical Methods",
  "analytical_method"
);

export const getPasswordPolicySettings = genericGet(
  "password_policy",
  "Getting Password Policy Settings",
  "password_policy_settings"
);

export async function editPasswordPolicySettings(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const res = await HttpClient.put(paths.password_policy, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Edit Password Policy");
    } else throw e;
  }
}

export async function getAnalyticalMethodsIdsAndNames(
  cancelToken?: CancelToken,
  showArchived?: boolean,
  options?: { doPagination: boolean }
) {
  let path =
    base_path +
    getFacilityPath() +
    `/analytical-method?doPagination=${options?.doPagination ? "true" : "false"}`;
  if (showArchived) {
    path = base_path + getFacilityPath() + "/archived/analytical-method";
  }
  return genericGetIdAndNameForFacility(
    path,
    "Getting Analytical Methods",
    "analytical_method",
    cancelToken && cancelToken,
    showArchived
  );
}

export async function addAnalyticalMethod(obj: FormData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  try {
    const path = base_path + getFacilityPath() + "/analytical-method";
    const res = await HttpClient.post(path, obj, config);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Add Analytical Method");
    } else throw e;
  }
}

export async function editAnalyticalMethod(analyticalMethodId: UUIDType, obj: FormData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  try {
    const path = base_path + getFacilityPath() + `/analytical-method/${analyticalMethodId}`;
    const res = await HttpClient.post(path, obj, config);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Edit Analytical Method");
    } else throw e;
  }
}

export const deleteAnalyticalMethod = genericDeleteForFacility(
  "analytical-method",
  "Analytical Method"
);

export async function getAuditLogsPaginated(
  pageLimit: number,
  pageNumber: number,
  UIFilter?: any,
  facilityId?: string | null
) {
  const options = HttpClient.requestConfig();

  const filter = {} as any;
  if (UIFilter) {
    if (UIFilter.category) {
      filter.domains = UIFilter.category;
    }
    if (UIFilter.action) {
      filter.actions = UIFilter.action;
    }
    if (UIFilter.email) {
      filter.userIds = UIFilter.email;
    }
    if (UIFilter.facilityId) {
      filter.facilityId = UIFilter.facilityId;
    }
    if (UIFilter.filterDates) {
      filter.startDate = UIFilter.filterDates.startDate;
      filter.endDate = UIFilter.filterDates.endDate;
    }
  }

  try {
    const params = {
      pageLimit,
      pageNumber,
      ...filter
    };

    if (facilityId) {
      params.facilityId = facilityId;
    }

    const finalOptions = { params, ...options };
    const res = await HttpClient.get(paths.log_path, finalOptions);
    return res.data.audit_logs;
  } catch (e) {
    if (e.response) {
      const action = "Getting audit logs paginated";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export const getAuditLogsFiltered = async (facilityId: string | null, UIFilter?: any) => {
  const path = base_path + "/audit_logs/export";
  const options = HttpClient.requestConfig();
  const filter = {} as any;
  filter.currentFacilityId = facilityId;
  if (UIFilter) {
    if (UIFilter.category) {
      filter.domains = UIFilter.category;
    }
    if (UIFilter.action) {
      filter.actions = UIFilter.action;
    }
    if (UIFilter.email) {
      filter.userIds = UIFilter.email;
    }
    if (UIFilter.facilityId) {
      filter.facilityId = UIFilter.facilityId;
    }
    if (UIFilter.filterDates) {
      filter.startDate = UIFilter.filterDates.startDate;
      filter.endDate = UIFilter.filterDates.endDate;
    }
  }
  try {
    const params = {
      ...filter
    };

    const finalOptions = { params, ...options };
    const res = (await HttpClient.get(path, {
      ...finalOptions,
      responseType: "blob"
    })) as any;
    const blob = new Blob([res.data], {
      type: "application/pdf"
    });
    const reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `Audit Logs.pdf`);
    document.body.appendChild(link);
    link.click();
    return res;
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      // Read file
      const file = await fileReader(data);
      // Parse content and retrieve 'message'
      const errMessage = JSON.parse(file).error.message;

      e.response.data.error = errMessage;
      const action = "Getting audit logs PDF";
      matchStatusAndThrowError(e, action);
    }
    return;
  }
};

export async function getAuditLogsFromReportPaginated(pageLimit: number, pageNumber: number) {
  const options = HttpClient.requestConfig();

  try {
    const params =
      pageLimit && !pageNumber && pageNumber !== 0
        ? { pageLimit }
        : {
            pageLimit,
            pageNumber
          };
    const finalOptions = { params, ...options };
    const res = await HttpClient.get(paths.log_report_path, finalOptions);
    return res.data.audit_logs;
  } catch (e) {
    if (e.response) {
      const action = "Getting audit logs paginated";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export const getUsers = genericGet("users_path", "Getting user", "users");
export async function getUsersIdsAndNames(cancelToken?: CancelToken) {
  return genericGetIdAndNameForFacility(
    base_path + "/users",
    "Getting Users",
    "users",
    cancelToken && cancelToken
  );
}
export async function getAuditLogUsers(cancelToken?: CancelToken) {
  return genericGetIdAndNameForFacility(
    base_path + "/audit_logs/users",
    "Getting Users for Audit ogs",
    "result",
    cancelToken && cancelToken
  );
}
export async function getOperatorsIdsAndNames(cancelToken?: CancelToken) {
  return genericGetIdAndNameForFacility(
    base_path + getFacilityPath() + "/operator",
    "Getting Operators",
    "operators",
    cancelToken && cancelToken
  );
}

export async function getOperatorName(userId: string, cancelToken?: CancelToken) {
  return genericGetIdAndNameForFacility(
    base_path + getFacilityPath() + `/operator/name/${userId}`,
    "Getting Operator Name",
    "operator",
    cancelToken && cancelToken
  );
}

export const getDepartments = genericGet("department_path", "Getting departments", "departments");

export const getAllowedDomains = genericGet(
  "allowed_domains",
  "Getting allowed domains",
  "domains"
);

export async function getDepartmentsIdsAndNames(cancelToken?: CancelToken) {
  return genericGetIdAndNameForFacility(
    base_path + "/departments",
    "Getting Departments",
    "departments",
    cancelToken && cancelToken
  );
}
//NotAuthorizedToAccessUserRoleError
export const getUserRoles = async () => {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/user_roles";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.user_roles;
  } catch (e) {
    if (e.response) {
      const action = "Getting User Roles";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
};
//NotAuthorizedToAccessUserRoleError
export const canUserAccessUserManagement = async () => {
  const options = HttpClient.requestConfig();
  const path = base_path + "/UAM/access";
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Checking if user can access user access management";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
};

export async function getProductionsPaginated(filterObj?: FilterDataObject, id?: number) {
  const authOptions = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/productions";
  if (filterObj && filterObj.showArchived) {
    path = base_path + getFacilityPath() + "/archived/productions";
  }
  if (id) {
    path += `/${id}`;
  }

  let filter = {};
  let pageNumber = 1;
  let pageLimit = 10;
  if (filterObj) {
    const { search, pagination } = filterObj;
    filter = (search && { nameOrExternalId: search }) || {};
    pageNumber = (pagination && pagination.pageNumber) || 1;
    pageLimit = (pagination && pagination.pageLimit) || 10;
  }
  const table = true;

  const params = { table, pageNumber, pageLimit, ...filter };
  const options = { params, ...authOptions };
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting Productions Paginated";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getProductGroupsPaginated(obj: {
  pageNumber?: PaginationConfig["current"];
  pageLimit?: PaginationConfig["pageSize"];
  id?: number;
  showArchived?: boolean;
}) {
  const { pageNumber, pageLimit, id, showArchived } = obj;
  const authOptions = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/product_groups";
  if (id) {
    path += `/${id}`;
  }
  if (showArchived) {
    path = base_path + getFacilityPath() + "/archived/product_groups";
  }
  const params = { pageNumber, pageLimit };
  const options = { params, ...authOptions };
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting Production Groups Paginated";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getEquipmentGroupsPaginated(filterObj?: FilterDataObject, id?: number) {
  const authOptions = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/equipment_groups";
  if (id) {
    path += `/${id}`;
  }
  if (filterObj && filterObj.showArchived) {
    path = base_path + getFacilityPath() + "/archived/equipment_groups";
  }

  let filters = {};
  let pageNumber = 1;
  let pageLimit = 10;
  if (filterObj) {
    const { search, pagination } = filterObj;
    filters = search ? { search, columns: "name,externalId" } : {};
    pageNumber = (pagination && pagination.pageNumber) || 1;
    pageLimit = (pagination && pagination.pageLimit) || 10;
  }

  const params = { pageNumber, pageLimit, ...filters };
  const options = { params, ...authOptions };
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment Groups Paginated";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getEquipmentGroupSubtableData(
  id: number,
  subTable: string,
  filterObj?: FilterDataObject & { paginate?: boolean }
) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipment_groups/${id}/${subTable}`;

  let filters = {};
  let pageNumber = 1;
  let pageLimit = 10;
  let doPagination = true;
  if (filterObj) {
    const { search, pagination, paginate } = filterObj;
    filters = search ? { search } : {};
    pageNumber = (pagination && pagination.pageNumber) || 1;
    pageLimit = (pagination && pagination.pageLimit) || 10;
    doPagination = paginate === false ? false : true;
  }

  const params = { pageNumber, pageLimit, doPagination, ...filters };
  const options = { params, ...authOptions };
  try {
    const res = await HttpClient.get(path, options);
    // TODO: Check the res object for both cases after API is ready
    const data =
      subTable === EquipmentGroupTables.PRODUCTS
        ? res.data.result.products
        : res.data.equipmentGroups.equipments;
    const total =
      subTable === EquipmentGroupTables.PRODUCTS
        ? res.data.result.totalRecords
        : res.data.equipmentGroups.totalRecords;
    // TODO: check the res object after API is ready
    return { data, total };
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment Groups Products";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getLocationSamplingData(equipmentId: number, locactionId: number) {
  const options = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    `/equipments/${equipmentId}/sampling-locations/${locactionId}/samples`;
  try {
    const res = await HttpClient.get(path, options);
    return res.data.swab_locations;
  } catch (e) {
    if (e.response) {
      const action = "Getting Locations Samples";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function unarchiveLocationSamplingData(
  equipmentId: number,
  locactionId: number,
  sampleId: number,
  reason: string
) {
  const authOptions = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    `/equipments/${equipmentId}/sampling-locations/${locactionId}/samples/${sampleId}`;
  const options = {
    unArchive: true,
    reason
  };
  try {
    const res = await HttpClient.put(path, options, authOptions);
    return res.status;
  } catch (e) {
    if (e.response) {
      const action = "Unarchiving Locations Samples";
      matchStatusAndThrowError(e, action);
      return;
    } else throw e;
  }
}

export async function archiveLocationSamplingData(
  equipmentId: number,
  locactionId: number,
  sampleId: number,
  reason: string
) {
  const authOptions = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    `/equipments/${equipmentId}/sampling-locations/${locactionId}/samples/${sampleId}`;
  const options = {
    reason
  };
  try {
    const res = await HttpClient.put(path, options, authOptions);
    return res.status;
  } catch (e) {
    if (e.response) {
      const action = "Archiving Locations Samples";
      matchStatusAndThrowError(e, action);
      return;
    } else throw e;
  }
}

export async function editLocationSamplingData(
  equipmentId: number,
  locactionId: number,
  data: SampleObjType,
  sampleId: number
) {
  const authOptions = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    `/equipments/${equipmentId}/sampling-locations/${locactionId}/samples/${sampleId}`;

  const options = {
    ...data
  };
  try {
    await HttpClient.post(path, options, authOptions);
  } catch (e) {
    if (e.response) {
      const action = "Editing Locations Samples";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function addLocationSamplingData(
  equipmentId: number,
  locactionId: number,
  data: SampleObjType
) {
  const authOptions = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    `/equipments/${equipmentId}/sampling-locations/${locactionId}/samples`;

  const options = {
    ...data
  };
  try {
    await HttpClient.post(path, options, authOptions);
  } catch (e) {
    if (e.response) {
      const action = "Add Locations Samples";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getSamplingLocationTypes() {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/sampling-types";
  try {
    const res = await HttpClient.get(path, authOptions);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment Groups Paginated";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getSubEquipmentsFromEquipmentGroup(id: number) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/equipment_groups" + `/${id}/equipments`;
  try {
    const res = await HttpClient.get(path, authOptions);
    return res.data.result.equipments;
  } catch (e) {
    if (e.response) {
      const action = "Getting equipment of equipment groups";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getProductsForApis(apis: any[]) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/apis/products";
  try {
    const res = await HttpClient.post(path, { apis }, options);
    return res.data.products;
  } catch (e) {
    if (e.response) {
      const action = "Getting Products for APIs";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getProductsIdAndName(cancelToken?: CancelToken) {
  return genericGetIdAndNameForFacility(
    base_path + getFacilityPath() + "/products",
    "Getting product Id and Name",
    "products",
    cancelToken && cancelToken
  );
}

export async function getCleaningAgents(id?: number, showArchived?: boolean) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/cleaning_agents";
  if (id) {
    path += `/${id}`;
  }

  if (showArchived) {
    path = base_path + getFacilityPath() + "/archived/cleaning_agents";
  }
  try {
    const res = await HttpClient.get(path, options);
    return res.data.cleaning_agents || res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Cleaning Agents";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getCleaningAgentsIdsAndNames(cancelToken?: CancelToken) {
  return genericGetIdAndNameForFacility(
    base_path + getFacilityPath() + "/cleaning_agents",
    "Getting cleaning agents",
    "cleaning_agents",
    cancelToken && cancelToken
  );
}

export async function getProductTypes(id?: number) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/product_types";
  if (id) {
    path += `/${id}`;
  }
  try {
    const res = await HttpClient.get(path, options);
    return res.data.product_types;
  } catch (e) {
    if (e.response) {
      const action = "Getting Product Types";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getEquipments(id?: number) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/equipments";
  if (id) {
    path += `/${id}`;
  }
  try {
    const res = await HttpClient.get(path, options);
    return res.data.equipments;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getProductionsForEquipment(
  id: string,
  filterObj: FilterDataObject,
  doPagination?: boolean
) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${id}/production-mapping`;
  const params: {
    pageNumber?: number | undefined;
    pageLimit?: number | undefined;
    doPagination?: string;
  } = {
    ...filterObj.pagination
  };

  if (doPagination) {
    params.doPagination = "true";
  }
  try {
    const res = await HttpClient.get(path, { ...options, params });
    return { data: res.data.equipments, total: res.data.equipments.totalRecords };
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getSamplingPolicies() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/sampling-policy";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result.policy;
  } catch (e) {
    if (e.response) {
      const action = "Fetch sampling Location";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function deleteSamplingPolicies(policyId: number, reason: string) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/sampling-policy/${policyId}`;
  try {
    const res = await HttpClient.delete(path, { ...options, data: { reason } });
    return res.status;
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    } else throw e;
  }
}

export async function addSamplingPolicies(samplingPolicyData: {
  id?: number;
  minLocations: number | undefined;
  contactType: SamplingLocationsContactType;
  operator?: Operator;
  samplingSubPolicies: {
    id?: number;
    operator: Operator;
    samplingSubPolicyAttributeMapping: SamplingSubPolicyAttrMappingType[];
  }[];
  reason: string;
}) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/sampling-policy";
  const options = { ...samplingPolicyData };
  try {
    await HttpClient.post(path, options, authOptions);
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    } else throw e;
  }
}

export async function editSamplingPolicies(samplingPolicyData: {
  id?: number;
  minLocations: number | undefined;
  contactType: SamplingLocationsContactType;
  operator?: Operator;
  samplingSubPolicies: {
    id?: number;
    operator: Operator;
    samplingSubPolicyAttributeMapping: SamplingSubPolicyAttrMappingType[];
  }[];
  reason: string;
}) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/sampling-policy/${samplingPolicyData.id}`;
  const options = { ...samplingPolicyData };
  try {
    await HttpClient.put(path, options, authOptions);
  } catch (e) {
    if (e.response) {
      throw e.response.data;
    } else throw e;
  }
}

// changed locations -> sampling-locations
export async function archiveEquipmentLocations(locId: number, eqId: number, reason: string) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + `/equipments/${eqId}/sampling-locations`;
  if (locId) {
    path += `/${locId}`;
  }
  try {
    const res = await HttpClient.delete(path, { ...options, data: { reason } });
    return res.status;
  } catch (e) {
    if (e.response) {
      const action = "Archiving Sample Location";
      matchStatusAndThrowError(e, action);
      return;
    } else throw e;
  }
}

// changed locations -> sampling-locations
export async function startAssessmentChanges(eqId: number, reason: string) {
  const authOptions = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/equipments/${eqId}/sampling-locations/assess`;
    const options = {
      equipmentId: eqId,
      reason
    };
    const result = await HttpClient.post(path, options, authOptions);
    return result.status;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "action");
      return;
    } else throw e;
  }
}

// changed locations -> sampling-locations
export async function UnArchiveEquipmentLocations(locId: number, eqId: number, reason: string) {
  const authOptions = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + `/equipments/${eqId}/sampling-locations`;
  if (locId) {
    path += `/${locId}`;
  }
  const options = {
    reason
  };
  try {
    const res = await HttpClient.put(path, options, authOptions);
    return res.status;
  } catch (e) {
    if (e.response) {
      const action = "Unarchiving Sample Location";
      matchStatusAndThrowError(e, action);
      return;
    } else throw e;
  }
}

// changed locations -> sampling-locations
export async function getEquipmentLocations(eqId: number) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${eqId}/sampling-locations`;
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment Locations";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getLocationImageDetails(eqId: number) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${eqId}/images`;
  try {
    const response = await HttpClient.get(path, options);
    return response.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Locations Images";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getLocationFullSizeImage(eqId: number, imageId: number) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${eqId}/images/${imageId}`;
  try {
    const response = await HttpClient.get(path, options);
    return response.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Locations Full Size Image";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function deleteLocationImageDetails(id: number, equipmentId: number, reason: string) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${equipmentId}/images/${id}`;
  try {
    const res = await HttpClient.delete(path, { ...options, data: { reason } });
    return res.status;
  } catch (e) {
    if (e.response) {
      if (e.response.status === 403) {
        throw e.response.data.error;
      } else {
        const action = "Deleting location image details";
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
}

export async function postLocationImageDetails(eqId: number, data: any, reason: string) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${eqId}/images`;
  const options = {
    imageData: data,
    reason
  };
  try {
    const response = await HttpClient.post(path, options, authOptions);
    return response.status;
  } catch (e) {
    if (e.response) {
      if (e.response.status === 403) {
        throw e.response.data.error;
      } else {
        const action = "Posting Locations Images";
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
}

export async function downloadSamplingSheet(eqId: number) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/reports/equipment/${eqId}/pdf`;
  let result;
  let reportUrl;
  const options = { ...authOptions, responseType: "blob" as "blob" };
  try {
    result = await HttpClient.get(path, options);
    const blob = new Blob([result.data], {
      type: "application/pdf"
    });
    reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `sampling-sheet.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      const action = "Downloading Sampling Sheet";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

// changed locations -> sampling-locations
export async function postEquipmentLocations(
  eqId: number,
  data: any,
  reason: string,
  editAction: boolean
) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${eqId}/sampling-locations`;
  const options = {
    data,
    equipmentId: eqId,
    reason
  };
  try {
    const res = await HttpClient.post(path, options, authOptions);
    return res.status;
  } catch (e) {
    if (e.response) {
      const action = `${editAction ? "Edit" : "Add"} sample location`;
      matchStatusAndThrowError(e, action);
      return;
    } else throw e;
  }
}

export async function getEquipmentWithNoGroup() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/equipments/filter/no-group";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment which don't belong to a group";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getEquipmentWithNoCPN() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/equipments/filter/no-cpn";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment with No CPN";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getEquipmentWithNoRoomGrade() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/equipments/filter/no-grade";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment with No Room Grade";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getCustomPropertiesByProduction(id: string) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/eq-prd-additional-fields/" + id;
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Custom Properties By Production Id : " + id;
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function mapCustomPropertiesByProduction(
  payload: { eqAdditionalFieldMapping: EqAdditionalFieldMapping; reason: string },
  id: string
) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/eq-prd-additional-fields/${id}`;
  try {
    const res = await HttpClient.post(path, payload, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Mapping Custom Properties By Production Id : " + id;
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getEquipmentsPaginated(filterObj?: FilterDataObject, id?: number) {
  const authOptions = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/equipments";
  if (filterObj && filterObj.showArchived) {
    path = base_path + getFacilityPath() + "/archived/equipments";
  }
  if (id) {
    path += `/${id}`;
  }

  let filters = {};
  let pageNumber = 1;
  let pageLimit = 10;
  if (filterObj) {
    const { pagination, search } = filterObj;
    filters = search ? { search, columns: "name,externalId" } : {};
    pageNumber = (pagination && pagination.pageNumber) || 1;
    pageLimit = (pagination && pagination.pageLimit) || 10;
  }

  const params = { pageNumber, pageLimit, ...filters };
  const options = { params, ...authOptions };
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

// changed sampling-locations-locations
export async function getEquipmentSamplingLocations(id: number, showArchived?: boolean) {
  const authOptions = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + `/equipments/${id}/locations`;
  if (showArchived) {
    path = base_path + getFacilityPath() + `/equipments/${id}/archived/locations`;
  }
  const options = { ...authOptions };
  try {
    const res = await HttpClient.get(path, options);
    return showArchived ? res.data.result : res.data.swab_locations;
  } catch (e) {
    if (e.response) {
      const action = "Getting equipment archived sampling locations";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getProductions(id?: UUIDType, query?: string) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/productions";
  if (id) {
    path += `/${id}`;
  }

  if (query) {
    path += `?${query}`;
  }

  try {
    const res = await HttpClient.get(path, options);
    return res.data.productions;
  } catch (e) {
    if (e.response) {
      const action = "Getting Productions";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getProductionEqCpMapping(id?: UUIDType, query?: string) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/productions";
  if (id) {
    path += `/${id}`;
    path += "/prod-eq-cp-mapping";
  }

  if (query) {
    path += `?${query}`;
  }

  try {
    const res = await HttpClient.get(path, options);
    return res.data.productions;
  } catch (e) {
    if (e.response) {
      const action = "Getting Productions";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getEquipmentsForProduction(id: string, filterObj: FilterDataObject) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/productions/${id}/equipment-mapping`;
  const params = { ...filterObj.pagination };
  try {
    const res = await HttpClient.get(path, { ...options, params });
    // TODO: Check the res object after API is ready
    return { data: res.data.result.finalResult, total: res.data.result.totalRecords };
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment for Production";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getProductionMappings(id: string | number, queryParams?: string) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + `/productions/${id}/map`;
  if (queryParams) {
    path = `${path}?${queryParams}`;
  }
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result || {};
  } catch (e) {
    if (e.response) {
      const action = "Getting Productions";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getProductionsWithNoGroup() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/production-dropdown/no-group";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Equipment which don't belong to a group";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getProductionsIdAndName(cancelToken?: CancelToken, facilityId?: string) {
  return genericGetIdAndNameForFacility(
    base_path + getFacilityPath(facilityId) + "/productions",
    "Getting production Id and Name",
    "productions",
    cancelToken && cancelToken
  );
}
export async function getEquipmentsIdAndName(cancelToken?: CancelToken, facilityId?: UUIDType) {
  return genericGetIdAndNameForFacility(
    base_path + getFacilityPath(facilityId) + "/equipments",
    "Getting equipment Id and Name",
    "equipments",
    cancelToken && cancelToken
  );
}
export async function getEquipmentGroupsIdAndName(
  cancelToken?: CancelToken,
  facilityId?: UUIDType
) {
  return genericGetIdAndNameForFacility(
    base_path + getFacilityPath(facilityId) + "/equipment_groups",
    "Getting equipment groups Id and Name",
    "equipmentGroups",
    cancelToken && cancelToken
  );
}
export async function getCleaningLimitPolicies(residueType: CleaningLimitPolicyResidueType) {
  const path = base_path + getFacilityPath() + `/cleaning_limit_policies/${residueType}`;
  const options = HttpClient.requestConfig();
  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting cleaning limit policies");
    } else throw e;
  }
}
export const getApprovalPolicies = async (documentType: string, documentId?: number) => {
  const auth_options = HttpClient.requestConfig();
  let url = base_path + getFacilityPath() + "/approval_policies";
  if (documentId) {
    url = url + "/" + documentId;
  }
  const params = { documentType };
  const options = { params, ...auth_options };
  try {
    const res = await HttpClient.get(url, options);
    return res.data.approval_policies;
  } catch (e) {
    if (e.response) {
      const action = "Getting approval policies";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
};

export async function addMicrobialPolicy(obj: { [index: string]: any }) {
  try {
    const url = base_path + getFacilityPath() + "/microbial_cleaning_limit_policies";

    const res = await HttpClient.post(url, obj, HttpClient.requestConfig());
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Adding/Modifying microbial policy";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getMicrobialPolicy() {
  try {
    const url = base_path + getFacilityPath() + "/microbial_cleaning_limit_policies";

    const res = await HttpClient.get(url, HttpClient.requestConfig());
    return (res.data && res.data.result) || res.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting microbial policy";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getInspectionFormFieldSettings() {
  try {
    const url = base_path + getFacilityPath() + "/cleaning_inspection_settings";
    const res = await HttpClient.get(url, HttpClient.requestConfig());
    return res.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Fetch Inspection Settings");
    } else throw e;
  }
}

export async function postInspectionFormFieldSetting(
  runFormFields: RunFormFieldType,
  reason: string
) {
  try {
    const url = base_path + getFacilityPath() + "/cleaning_inspection_settings";
    await HttpClient.post(url, { ...runFormFields, reason }, HttpClient.requestConfig());
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Adding Inspection Settings");
    } else throw e;
  }
}

// changed sampling-location-locations
export async function getAM(obj: { [index: string]: any }) {
  try {
    const { equipmentId, locationId, residueType } = obj;

    const url =
      base_path +
      getFacilityPath() +
      `/equipments/${equipmentId}/sampling-locations/${locationId}/filter/microbial-am?residueType=${residueType}`;

    const res = await HttpClient.get(url, HttpClient.requestConfig());
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting AM";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getRoomGrade() {
  try {
    const url = base_path + getFacilityPath() + "/room-grades";

    const res = await HttpClient.get(url, HttpClient.requestConfig());
    return (res.data && res.data.result) || res.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting Room Grade";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export async function getSOPs() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/sop";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting SOP Documents";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function editSOP(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/sop`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Edit Facility Settings");
    } else throw e;
    return;
  }
}

export async function getSamplingMethod(equipmentId: number) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/equipments/" + equipmentId + "/sampling-methods";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.sampling_method;
  } catch (e) {
    if (e.response) {
      const action = "Getting Sampling Methods";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function addSamplingMethod(equipmentId: number, obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/equipments/" + equipmentId + "/sampling-methods";
  try {
    const res = await HttpClient.post(path, obj, options);
    return res;
  } catch (e) {
    if (e.response) {
      const action = "Add Sampling Methods";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
  return;
}

export async function editSamplingMethod(
  equipmentId: number,
  samplingMethodId: string,
  obj: { [index: string]: any }
) {
  const options = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    "/equipments/" +
    equipmentId +
    "/sampling-methods/" +
    samplingMethodId;
  try {
    const res = await HttpClient.post(path, obj, options);
    return res;
  } catch (e) {
    if (e.response) {
      const action = "Edit Sampling Methods";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
  return;
}

export async function sendFeedback(feedback: string, at: any) {
  const options = HttpClient.requestConfig();
  options.headers["Content-Type"] = "application/json";

  try {
    const res = await HttpClient.post(paths.feedback_path, { feedback, at }, options);
    return res;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Sending feedback");
    } else throw e;
    return;
  }
}

export async function addSelectionCriteria(data: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/selection-criteria";
  try {
    const res = await HttpClient.post(path, data, options);
    return res;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "");
    } else throw e;
    return;
  }
}

export async function editSelectionCriteria(data: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/selection-criteria";
  const pathWithId = path + "/" + data.criteriaId;
  try {
    const res = await HttpClient.post(pathWithId, data, options);
    return res;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "");
    } else throw e;
    return;
  }
}

export async function deleteSelectionCriteria(data: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/selection-criteria";
  const pathWithId = path + "/" + data.id;
  try {
    const res = await HttpClient.put(pathWithId, data, options);
    return res;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "");
    } else throw e;
    return;
  }
}

export async function getSelectionCriteria() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/selection-criteria";
  try {
    const res = await HttpClient.get(path, options);
    return res.data.selection_criteria;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "");
    } else throw e;
  }
}

// changed sampling-locations-locations
export async function addSwabLocation(equipmentId: number, obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/equipments/${equipmentId}/locations`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, `Add Sampling Location for Equipment ${equipmentId}`);
    } else throw e;
  }
}

// changed sampling-locations-locations
export async function getSamplingLocations(equipmentId: number, locationId?: number) {
  const options = HttpClient.requestConfig();
  try {
    let path = base_path + getFacilityPath() + `/equipments/${equipmentId}/locations/`;
    if (locationId) {
      path = path + locationId;
    }
    const res = await HttpClient.get(path, options);
    return res.data.swab_locations;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, `Get Sampling Location for Equipment ${equipmentId}`);
    } else throw e;
  }
}

// changed sampling-locations-locations
export async function deleteSamplingLocations(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path =
      base_path + getFacilityPath() + `/equipments/${obj.equipmentId}/locations/${obj.id}`;
    const res = await HttpClient.put(path, obj, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(
        e,
        `Archiving Sampling Location ${obj.id} for Equipment ${obj.equipmentId}`
      );
    } else throw e;
  }
}

// changed sampling-locations-locations
export async function editSwabLocation(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${obj.equipmentId}/locations/${obj.id}`;
  try {
    const res = await HttpClient.post(path, obj, options);
    return res;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "");
    } else throw e;
    return;
  }
}

export async function addAPI(obj: FormData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  try {
    const path = base_path + getFacilityPath() + "/apis";
    // if (!path) throw new Error(`Path not defined for ${pathKey}`);
    const res = await HttpClient.post(path, obj, config);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Add API");
    } else throw e;
  }
}

export async function addProduct(obj: { [index: string]: any }, action: string) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/products";
    // if (!path) throw new Error(`Path not defined for ${pathKey}`);
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function editProduct(
  obj: { [index: string]: any },
  id: number,
  action = "Editing product"
) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/products/${id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function addMOC(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/moc`;
    const res = await HttpClient.post(path, obj, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "addMOC");
    } else throw e;
  }
}

export async function getMOC() {
  try {
    const path = base_path + getFacilityPath() + `/moc`;
    const res = await HttpClient.get(path, HttpClient.requestConfig());
    return res.data && res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "getMOC");
    } else throw e;
  }
}

export async function addProduction(obj: { [index: string]: any }, action: string) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/productions";
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function addEquipment(obj: { [index: string]: any }, action: string) {
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  try {
    const path = base_path + getFacilityPath() + "/equipments";
    const res = await HttpClient.post(path, obj, config);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function addProductGroup(obj: { [index: string]: any }, action: string) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/product_groups";
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function addEquipmentGroup(obj: { [index: string]: any }, action: string) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/equipment_groups";
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function startCleaningAssessment(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/cleaning-assessments";
    const res = await HttpClient.post(path, obj, {
      timeout: 6000000,
      ...options
    });
    return res.data.cleaningEvaluation;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Start Change Assessment");
    } else throw e;
  }
}

export async function getCleaningAssessments() {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/cleaning-assessments";
    const res = await HttpClient.get(path, options);
    return res.data.cleaningEvaluation;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Get Change Assessments");
    } else throw e;
  }
}

export function doPriorAssessment() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/prior_assessment";
  return HttpClient.get(path, options);
}

export async function getChangesFromLastAssessment(pageLimit: number, pageNumber: number) {
  const options = HttpClient.requestConfig();

  try {
    const params =
      pageLimit && !pageNumber && pageNumber !== 0
        ? { pageLimit }
        : {
            pageLimit,
            pageNumber
          };
    const finalOptions = { params, ...options };
    const path = base_path + getFacilityPath() + "/cleaning-assessments/from-last";
    const res = await HttpClient.get(path, finalOptions);
    return res.data.audit_logs;
  } catch (e) {
    if (e.response) {
      const action = "Getting changes from last change assessment paginated";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function downloadAreaCalculationPdf(fileId: string, eqId: number) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/equipments/${eqId}/area-calculation-pdf/${fileId}`;
  const options = { ...authOptions, responseType: "blob" as "blob" };
  try {
    const result = await HttpClient.get(path, options);
    return result.data;
  } catch (e) {
    if (e.response) {
      const action = "Downloading Area calculation PDF";
      const { data } = e.response;
      if (data instanceof Blob) {
        const file = await fileReader(data);

        e.response.data = JSON.parse(file);
      }
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function downloadRefDoc(
  entityType: ReferenceDocumentEntityType,
  entityId: number,
  fileId: string
) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/${entityType}/${entityId}/document/${fileId}`;
  const options = { ...authOptions, responseType: "blob" as "blob" };
  try {
    const result = await HttpClient.get(path, options);
    return result.data;
  } catch (e) {
    if (e.response) {
      const action = `Downloading Reference Document for ${entityType}`;
      const { data } = e.response;
      if (data instanceof Blob) {
        const file = await fileReader(data);
        e.response.data = JSON.parse(file);
      }
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function editEquipment(equipmentId: number, obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/equipments/${equipmentId}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Editing equipment");
    } else throw e;
  }
}

export async function getLegacyDocument(
  documentType: LegacyDocumentType,
  filterObj?: FilterDataObject
) {
  const authOptions = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/legacy-documents/${documentType}`;

  let params = {
    search: "",
    pageNumber: 1,
    pageLimit: 10
  };

  if (filterObj) {
    const { pagination, search } = filterObj;

    if (search) {
      params = {
        ...params,
        search
      };
    }
    if (pagination) {
      params = {
        ...params,
        pageNumber: pagination.pageNumber || 1,
        pageLimit: pagination.pageLimit || 10
      };
    }
  }
  const options = { params, ...authOptions };

  try {
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    console.error(e);
  }
}

export async function editAPI(apiId: string, obj: FormData) {
  const config = {
    headers: {
      "content-type": "multipart/form-data"
    }
  };
  try {
    const path = base_path + getFacilityPath() + `/apis/${apiId}`;
    const res = await HttpClient.post(path, obj, config);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Edit API");
    } else throw e;
  }
}

export async function editProduction(
  obj: { [index: string]: any },
  id: number,
  action = "Change Surface Area",
  query?: string
) {
  const options = HttpClient.requestConfig();
  try {
    let path = base_path + getFacilityPath() + `/productions/${id}`;
    if (query) {
      path += `?${query}`;
    }
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function mapProduction(productionId: number | string, obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/productions/${productionId}/map`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    throw e;
  }
}

export async function editProductGroup(
  obj: { [index: string]: any },
  id: number,
  action = "Update Production Group"
) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/product_groups/${id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function editEquipmentGroup(
  obj: { [index: string]: any },
  id: number,
  action = "Update Equipment Group"
) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/equipment_groups/${id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function mapProductionsForEquipmentGroup(
  obj: { [index: string]: any },
  id: number,
  action = "Map Productions for Equipment Group"
) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/equipment_groups/${id}/map`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getMapppedProductionsForEquipmentGroup(
  id: number,
  action = "Get Mapped Productions for Equipment Group"
) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/equipment_groups/${id}/map`;
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getAPIs(
  id?: number,
  showArchived?: boolean,
  additionalOptions: { doPagination?: boolean } = { doPagination: true }
) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/apis";
  if (showArchived) {
    path = base_path + getFacilityPath() + "/archived/apis";
  }
  if (id) {
    path += `/${id}`;
  }
  path += `?doPagination=${!!additionalOptions.doPagination}`;
  try {
    const res = await HttpClient.get(path, options);
    return showArchived ? res.data.result : res.data.apis;
  } catch (e) {
    if (e.response) {
      const action = "Getting APIs";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getAPIProductProductionProperties() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/api-properties";

  try {
    const { data } = await HttpClient.get(path, options);
    return data;
  } catch (e) {
    if (e.response) {
      const action = "Getting API properties";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function downloadCustomReport(
  reportId: number,
  reportName: CustomReportType,
  queryParams?: { [index: string]: any },
  config?: { [index: string]: any }
) {
  const options = HttpClient.requestConfig();
  const dynamic_part =
    reportName === CustomReportType.MasterDataReport
      ? `/reports/${CustomReportFieldType.masterData}/${reportId}`
      : reportName === CustomReportType.WorstCaseRanking
      ? "/reports/worst-case-report"
      : `/custom-reports/${reportId}/report-data/download`;

  const path = base_path + getFacilityPath() + dynamic_part;
  try {
    const res = await HttpClient.get(path, {
      params: queryParams,
      ...options,
      responseType: "blob"
    });
    const isPDF = queryParams?.fileType === FileType.PDF;
    const fileType =
      reportName === CustomReportType.MasterDataReport ||
      (reportName === CustomReportType.WorstCaseRanking && isPDF)
        ? "application/pdf"
        : "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([res.data], {
      type: fileType
    });
    const reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    const timeStamp = moment().format("MMM Do YYYY h:mm:ss");
    if (reportName === CustomReportType.WorstCaseRanking && config) {
      const { equipment, criteria } = config;
      const criteriaName = JSON.parse(config.criteria).name;
      const equipmentExternalId = JSON.parse(config.equipment).externalId;
      const localDateTimeFormatter = createLocalDateTimeFormatter(
        "Asia/Calcutta",
        "DD-MM-YYYY",
        "HH:mm:ss"
      );
      link.setAttribute(
        "download",
        `${equipmentExternalId}_${criteriaName}_Worst-case Ranking Report-${localDateTimeFormatter.formatDate(
          moment().unix()
        )}${queryParams?.fileType ? `.${queryParams?.fileType}` : ""}`
      );
    } else {
      link.setAttribute("download", `${reportName} ${timeStamp}`);
    }
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      const action = `Getting ${reportName} report`;
      matchStatusAndThrowError(e, action);
    } else throw e;
    return;
  }
}

export async function downloadCustomImpactAssessmentReport(
  reportId: any,
  reportName: CustomReportType
) {
  const options = HttpClient.requestConfig();
  const dynamic_part =
    reportName === CustomReportType.ImpactAssessmentReport
      ? `/reports/${CustomReportFieldType.impactOnWorstCases}/${reportId}`
      : `/custom-reports/${reportId}/report-data/download`;
  const path = base_path + getFacilityPath() + dynamic_part;
  try {
    const res = await HttpClient.get(path, {
      ...options,
      responseType: "blob"
    });
    const fileType = "application/pdf";
    const blob = new Blob([res.data], {
      type: fileType
    });
    const reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    const timeStamp = moment().format("MMM Do YYYY h:mm:ss");
    link.setAttribute("download", `${reportName} ${timeStamp}`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      const action = `Getting ${reportName} report`;
      matchStatusAndThrowError(e, action);
    } else throw e;
    return;
  }
}

export async function getConfiguredReportTableData(reportId, configParams) {
  const auth_options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/custom-reports/${reportId}/report-data/data`;
  const options = { params: { perPage: 10, ...configParams }, ...auth_options };
  try {
    const result = await HttpClient.get(path, options);
    return result.data;
  } catch (e) {
    console.error(e);
  }
}

export async function getWorstCaseReportTableData(configParams: any) {
  const auth_options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/qualification-task/worst-cases`;
  const options = { params: { perPage: 10, ...configParams }, ...auth_options };
  try {
    const result = await HttpClient.get(path, options);
    return result.data;
  } catch (e) {
    console.error(e);
  }
}

export async function getConfiguredMasterDataReport(reportId, configParams, tabKey: string) {
  const auth_options = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    `/custom-reports/${reportId}/report-config/${CustomReportFieldType.masterData}/${tabKey}/data`;
  const options = { params: configParams, ...auth_options };
  try {
    const result = await HttpClient.get(path, options);
    return result.data.result;
  } catch (e) {
    console.error(e);
  }
}

export async function getConfiguredImpactAssessmentReport(reportId, configParams, tabKey: string) {
  const auth_options = HttpClient.requestConfig();

  const path = base_path + getFacilityPath() + `/custom-reports/${reportId}/report-data/${tabKey}`;

  const options = { params: configParams, ...auth_options };
  try {
    const result = await HttpClient.get(path, options);
    return result.data.data;
  } catch (e) {
    console.error(e);
  }
}

export async function getCustomReportsInfo() {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + "/custom-reports/report-info";

  try {
    const result = await HttpClient.get(path, options);
    return result.data;
  } catch (e) {
    if (e.response) {
      if (e.response?.data?.error?.name) {
        throw e.response.data.error;
      } else {
        const action = "Getting Custom Reports Info";
        matchStatusAndThrowError(e, action);
      }
    } else throw e;
  }
}

export async function getReportConfigFieldData(
  reportId: number,
  fieldType: string,
  params?: { [index: string]: number | string },
  reportType?: CustomReportType
) {
  const auth_options = HttpClient.requestConfig();
  const path =
    base_path +
    getFacilityPath() +
    (reportType === CustomReportType.WorstCaseRanking
      ? `/qualification-task/configure`
      : `/custom-reports/${reportId}/report-config/${fieldType}/data`);
  const options = params ? { params, ...auth_options } : auth_options;

  try {
    const result = await HttpClient.get(path, options);
    return result.data.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting Custom Reports Info";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getReportConfigChangeAssessmentFieldData(
  fieldType: string,
  reportId: number
) {
  const auth_options = HttpClient.requestConfig();
  const path =
    base_path + getFacilityPath() + `/custom-reports/${reportId}/report-data/${fieldType}`;
  try {
    const result = await HttpClient.get(path, auth_options);
    return result.data.data;
  } catch (e) {
    if (e.response) {
      const action = "Getting Custom Reports Info";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function getCleaningProcedures(id?: number, showArchived?: boolean) {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/cleaning-procedure";
  if (showArchived) {
    path = base_path + getFacilityPath() + "/archived/cleaning-procedure";
  }
  if (id) {
    path += `/${id}`;
  }
  try {
    const res = await HttpClient.get(path, options);
    return id ? res.data : res.data.result.cleaningProcedures;
  } catch (e) {
    if (e.response) {
      const action = "Getting Cleaning Procedures";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export async function addCleaningProcedures(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/cleaning-procedure";
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Add Cleaning Procedure");
    } else throw e;
  }
}

export async function editCleaningProcedures(id: number, obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/cleaning-procedure/${id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Edit Cleaning Procedure");
    } else throw e;
  }
}

export const deleteCleaningProcedures = genericDeleteForFacility(
  "cleaning-procedure",
  "Cleaning Procedure"
);

function generateReportApi(pathKey: string, action: string) {
  return async function (obj: { [index: string]: any }) {
    const options = HttpClient.requestConfig();
    options.headers["Content-Type"] = "application/json";

    try {
      const res = await HttpClient.post(paths[pathKey], obj, options);
      return res;
    } catch (e) {
      if (e.response) {
        matchStatusAndThrowError(e, action);
      } else throw e;
      return;
    }
  };
}

function genericAdd(pathKey: string, action: string) {
  return async function (obj: { [index: string]: any }) {
    const options = HttpClient.requestConfig();
    try {
      const path = paths[pathKey];
      if (!path) throw new Error(`Path not defined for ${pathKey}`);
      const res = await HttpClient.post(path, obj, options);
      return res.data.result;
    } catch (e) {
      if (e.response) {
        matchStatusAndThrowError(e, action);
      } else throw e;
    }
  };
}

export const addUser = genericAdd("users_path", "Adding user");
export async function addOperator(obj: { [index: string]: any }, action: string) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/operator";
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export const addUserRole = genericAdd("role_path", "Adding User Role");

export async function addCleaningAgent(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/cleaning_agents";
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Add Cleaning Agent");
    } else throw e;
  }
}

function genericEdit(pathKey: string, action: string) {
  return async function (obj: { [index: string]: any }) {
    const id = obj.id;
    const options = HttpClient.requestConfig();

    try {
      const path = `${paths[pathKey]}/${id}`;
      if (!path) throw new Error(`Path not defined for ${pathKey}`);
      const res = await HttpClient.post(path, obj, options);
      return res.data;
    } catch (e) {
      if (e.response) {
        matchStatusAndThrowError(e, action);
      } else throw e;
    }
  };
}

export async function editCleaningAgent(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/cleaning_agents/${obj.id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Edit Cleaning Agent");
    } else throw e;
  }
}

export const editUser = genericEdit("users_path", "Editing users");
export async function editOperator(obj: { [index: string]: any }, action = "Update operator") {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/operator/${obj.id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
export const editMacFormula = genericEdit("formula_path", "Editing Mac Formula");
export async function editDefaultUnit(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/default_units/${obj.id[0]}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Editing Default unit");
    } else throw e;
  }
}
export const editRiskFormula = genericEdit("rpn_formula_path", "Editing Risk Formula");
export const editRiskCategory = genericEdit("rpn_cat_path", "Editing Risk Category");

export const editUserRole = genericEdit("role_path", "Editing User Role");
export async function editMacVariable(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/variables/${obj.id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.variables;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Editing Mac Variable");
    } else throw e;
  }
}

export async function editVersionControl(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/document-version/${obj.id}`;
    const res = await HttpClient.post(path, obj, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Editing document version");
    } else throw e;
  }
}

export async function getAllDocumentVersions() {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/document-version/`;
    const res = await HttpClient.get(path, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Fetch all documents");
    } else throw e;
  }
}

export async function editCleaningLimitPolicies(obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/cleaning_limit_policies`;
    const res = await HttpClient.post(path, obj, options);
    return res;
  } catch (e) {
    throw e;
  }
}

export const editApprovalPolicy = async function (obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + "/approval_policies";
    const result = await HttpClient.post(path, obj, options);
    return result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Editing Approval Policy");
    } else throw e;
  }
};

export const cancelUserToken = async (id: number, token: string | null) => {
  const options = HttpClient.requestConfig();

  try {
    const path = base_path + `/users/${id}/tokens?token=${token}`;
    const res = (await HttpClient.delete(path, options)) as any;
    return res.status;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Cancel Secret Key");
    } else throw e;
  }
};

export async function resendDetailsToUser(obj: { [index: string]: any }) {
  try {
    const path = paths.users_resend_invitation;
    const options = obj.isEmail
      ? {
          id: obj.id,
          reason: obj.reason
        }
      : {
          id: obj.id,
          reason: obj.reason,
          generateKey: obj.generateKey
        };

    const result = await HttpClient.post(path, options, HttpClient.requestConfig());
    return result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "action");
    } else throw e;
  }
}

export async function getAssessmentAttribute() {
  const options = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/attributes`;
    const result = await HttpClient.get(path, options);
    return result.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting sampling assessment attribute");
    } else throw e;
  }
}

export async function editUnarchiveAssessmentAttribute(obj: any, attrId: number) {
  const authOptions = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/attributes/${attrId}?unarchive=${obj.unarchive}`;
    const options = { ...obj };
    const result = await HttpClient.put(path, options, authOptions);
    return result.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(
        e,
        `${obj.unarchive ? "Unarchive" : "Editing"} sampling assessment attribute`
      );
    } else throw e;
  }
}

export async function addAssessmentAttribute(obj: any) {
  const authOptions = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/attributes`;
    const options = { ...obj };
    const result = await HttpClient.post(path, options, authOptions);
    return result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Add sampling assessment attribute");
      return;
    } else throw e;
  }
}

export async function archiveAttribute(id: number, reason: string) {
  const authOptions = HttpClient.requestConfig();
  try {
    const path = base_path + getFacilityPath() + `/attributes/${id}`;
    const result = await HttpClient.delete(path, { ...authOptions, data: { reason } });
    return result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Archive sampling assessment attribute");
      return;
    } else throw e;
  }
}

function genericDelete(pathKey: string, action: string) {
  return async function (obj: { [index: string]: any }) {
    const id = obj.id;
    const force_delete = obj.force_delete;
    const reason = obj.reason;
    const options = HttpClient.requestConfig();

    try {
      let path = `${paths[pathKey]}/${id}`;
      if (action === "Archiving user") {
        path = path + "/archive";
      }
      if (!path) throw new Error(`Path not defined for ${pathKey}`);
      const result = await HttpClient.put(path, { reason, force_delete }, options);
      return result.data;
    } catch (e) {
      if (e.response) {
        matchStatusAndThrowError(e, action);
      } else throw e;
    }
  };
}

export async function unArchiveOperator(obj: { [index: string]: any }) {
  const action = "Unarchiving operator";
  const { reason, id } = obj;
  try {
    const options = HttpClient.requestConfig();
    const path = `${base_path}${getFacilityPath()}/operator/${id}`;
    const result = await HttpClient.put(path, { reason, unArchive: true }, options);
    return result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}
function genericDeleteForFacility(endpoint: string, type: string) {
  return async function (obj: { [index: string]: any }) {
    const action = `${obj.unArchive ? "Unarchiving" : "Archiving"} ${type}`;
    const id = obj.id;
    const options = HttpClient.requestConfig();

    try {
      const path = `${base_path}${getFacilityPath()}/${endpoint}/${id}`;
      const result = await HttpClient.put(path, obj, options);
      return result.data;
    } catch (e) {
      if (e.response) {
        matchStatusAndThrowError(e, action);
      } else throw e;
    }
  };
}

export const deleteEquipment = genericDeleteForFacility("equipments", "equipment");
export const deleteEquipmentGroup = genericDeleteForFacility("equipment_groups", "equipment group");
export const deleteAPI = genericDeleteForFacility("apis", "API");
export const deleteNitrosamine = genericDeleteForFacility("nitrosamines", "Nitrosamine");
export const deleteIntermediate = genericDeleteForFacility("intermediates", "Intermediate");
export const deleteProduction = genericDeleteForFacility("productions", "production");
export const deleteProductGroup = genericDeleteForFacility("product_groups", "Production Groups");
export const deleteProduct = genericDeleteForFacility("products", "product");
export const deleteCleaningAgent = genericDeleteForFacility("cleaning_agents", "Cleaning Agent");
export const deleteUserRole = genericDelete("role_path", "Archiving User Role");
export const archiveOperator = genericDeleteForFacility("operator", "operator");

export const archiveUser = genericDelete("users_path", "Archiving user");
export const unBlockUser = genericDelete("users_unblock_path", "Unlocking user");
export const blockUser = genericDelete("users_block_path", "Locking user");
export const enableUser = genericDelete("users_enable_path", "Unarchiving user");

export async function getToxicityScore(facilityId: string) {
  const url = `${base_path}${getFacilityPath(facilityId)}/dashboard/toxicity-score`;
  const options = HttpClient.requestConfig();
  let result;
  try {
    result = await HttpClient.get(url, options);
    return result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Toxicity Score");
    } else throw e;
  }
}

export async function getDashboardStats(facilityId?: string) {
  const url = facilityId
    ? base_path + getFacilityPath(facilityId) + "/dashboard"
    : base_path + getFacilityPath() + "/dashboard";
  const options = HttpClient.requestConfig();
  let result;
  try {
    result = await HttpClient.get(url, options);
    result = result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting dashboard statistics");
    } else throw e;
  }

  return result;
}

export async function getDashboard(dashboardId: string, facilityId?: string) {
  let url = facilityId
    ? base_path + getFacilityPath(facilityId) + "/dashboard/quicksight"
    : base_path + getFacilityPath() + "/dashboard/quicksight";

  url = `${url}?dashboardId=${dashboardId}`;
  const options = HttpClient.requestConfig();
  let result;
  try {
    result = await HttpClient.get(url, options);
    result = result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting dashboard statistics");
    } else throw e;
  }

  return result;
}

export async function getDashboardsList(facilityId?: string) {
  const url = facilityId
    ? base_path + getFacilityPath(facilityId) + "/dashboard/qslist"
    : base_path + getFacilityPath() + "/dashboard/qslist";
  const options = HttpClient.requestConfig();
  let result;
  try {
    result = await HttpClient.get(url, options);
    result = result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Cannot retrieve dashboard list ");
    } else throw e;
  }

  return result;
}

export async function getLocationImage(url: string) {
  const options = HttpClient.requestConfig();
  let result;
  let imageUrl;
  try {
    result = await HttpClient.get(`${paths["swab_location_path"]}${url}`, {
      ...options,
      responseType: "blob"
    });
    const blob = new Blob([result.data], {
      type: "image/png"
    });
    imageUrl = window.URL.createObjectURL(blob);
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Sampling Location Image");
    } else throw e;
  }
  return imageUrl;
}

export const downloadLegacyDocument = async (
  type: LegacyDocumentType,
  fileId: number,
  fileName: string
) => {
  const path = base_path + getFacilityPath() + `/legacy-documents/${type}/${fileId}`;
  const options = HttpClient.requestConfig();
  try {
    const res = await HttpClient.get(path, {
      ...options,
      responseType: "blob"
    });
    const blob = new Blob([res.data], {
      type: "application/pdf"
    });
    const reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      const action = `Getting ${fileName}`;
      matchStatusAndThrowError(e, action);
    } else throw e;
    return;
  }
};

export async function getTags() {
  let result;

  try {
    const url = base_path + getFacilityPath() + `/reports/get-report-tags`;
    result = await HttpClient.get(url, {});
    result = result.data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Report File");
    } else throw e;
  }
  return result;
}

export async function getReport(
  documentType: ReportDocumentType | undefined,
  reportId: string | number,
  fileName: string | undefined,
  pid?: string | undefined
) {
  const authOptions = HttpClient.requestConfig();
  let result;
  let reportUrl;
  let url =
    base_path + getFacilityPath() + `/reports/reportDocument/${reportId}/pdf/${documentType}`;

  if (pid) {
    url += `?productionId=${pid}`;
  }
  const options = { ...authOptions, responseType: "blob" as "blob" };
  try {
    result = await HttpClient.get(url, options);
    const blob = new Blob([result.data], {
      type: "application/pdf"
    });
    reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `${fileName}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Report File");
    } else throw e;
  }
  return reportUrl;
}

export async function getMACMatrix(
  productionId: string,
  filename: string,
  isNitrosamine?: boolean
) {
  const authOptions = HttpClient.requestConfig();
  let result;
  let reportUrl;
  const url =
    base_path +
    getFacilityPath() +
    `/reports/macMatrix/production/${productionId}${isNitrosamine ? "/nitrosamine" : ""}`;

  const options = { ...authOptions, responseType: "blob" as "blob" };
  try {
    result = await HttpClient.get(url, options);
    const blob = new Blob([result.data], {
      type: "application/pdf"
    });
    reportUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = reportUrl;
    link.setAttribute("download", `${filename}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Report File");
    } else throw e;
  }
  return reportUrl;
}

export async function getRiskGoal(obj: { [index: string]: any }) {
  const url = base_path + getFacilityPath() + "/risk-goal";
  const options = HttpClient.requestConfig();
  let result;
  try {
    result = await HttpClient.post(url, obj, options);
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting Risk Goal");
    } else throw e;
  }
  return result!.data.result;
}

export async function getFeatureFlags(): Promise<FeatureFlagsType> {
  const storedFlags = sessionStorage.getItem(SessionStorageKeys.FEATURE_FLAGS);
  if (storedFlags) {
    return JSON.parse(storedFlags);
  }

  const url = paths.features_path;
  const options = HttpClient.requestConfig();
  let featureFlags: FeatureFlagsType | undefined;
  try {
    const { data } = await HttpClient.get(url, options);
    featureFlags = data;
    sessionStorage.setItem(SessionStorageKeys.FEATURE_FLAGS, JSON.stringify(featureFlags));
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Getting feature flags");
    } else throw e;
  }

  return featureFlags!;
}

export const getQualificationTasks = async (req: {
  caseStudyId?: number;
  evaluationId?: number;
}) => {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/qualification-task";
  if (req.caseStudyId) {
    path += `/entity/${req.caseStudyId}`;
  }
  try {
    const resObj = req.evaluationId
      ? { params: { evaluationId: req.evaluationId }, ...options }
      : options;
    const res = await HttpClient.get(path, resObj);
    return res.data.qualification_tasks;
  } catch (e) {
    if (e.response) {
      const action = "Getting Qualification Tasks";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
};

export async function performFacilityQualificationTask(id: number, obj: { [index: string]: any }) {
  const options = HttpClient.requestConfig();
  const path = base_path + getFacilityPath() + `/qualification-task/task/${id}`;

  try {
    const res = await HttpClient.post(path, obj, {
      timeout: 6000000,
      ...options
    });
    return res.data.result;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, `Perform QualificationTask ${id}`);
    } else throw e;
  }
}

export async function getRoomGrades(id?: number): Promise<any[]> {
  const options = HttpClient.requestConfig();
  let path = base_path + getFacilityPath() + "/room-grades";
  if (id) {
    path += `/${id}`;
  }

  try {
    const res = await HttpClient.get(path, options);
    return res.data.result;
  } catch (e) {
    if (e.response) {
      const action = "Getting Room Grades";
      matchStatusAndThrowError(e, action);
    } else throw e;

    return [];
  }
}

export async function assignEquipmentToRoomGrade(roomId: number, eqIds: number[], reason: string) {
  const options = HttpClient.requestConfig();

  try {
    const path = base_path + getFacilityPath() + `/room-grades/${roomId}`;
    const resObj = { equipments: eqIds, reason };
    const res = await HttpClient.post(path, resObj, options);
    return res.data;
  } catch (e) {
    if (e.response) {
      const action = "Assigning Equipment to Room Grade";
      matchStatusAndThrowError(e, action);
    } else throw e;
  }
}

export const getActiveDirectorySettings = genericGet(
  "activeDirectorySettings",
  "Getting Active Directory Settings",
  "activeDirectorySettings"
);

export const generateAnnualSummaryReport = async (
  start: string,
  end: string,
  customSections?: { header: string; content: string }[]
) => {
  const options = HttpClient.requestConfig();
  try {
    const { data, headers } = await HttpClient.post(
      `${base_path}${getFacilityPath()}/reports/annualSummary`,
      { start, end, customSections },
      { ...options, responseType: "blob" }
    );
    const blob = new Blob([data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `${headers.filename}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      if (data instanceof Blob) {
        // Read file
        const file = await fileReader(data);
        // Parse content and retrieve 'data'
        e.response.data = JSON.parse(file);
      }
      matchStatusAndThrowError(e, "Generate Annual Summary Report");
    }
    return;
  }
};

export const downloadAnnualSummaryReport = async (id: number) => {
  const options = HttpClient.requestConfig();
  try {
    const { data, headers } = await HttpClient.get(
      `${base_path}${getFacilityPath()}/reports/annualSummary/${id}`,
      {
        ...options,
        params: { download: true },
        responseType: "blob"
      }
    );
    const blob = new Blob([data], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", `${headers.filename}.pdf`);
    document.body.appendChild(link);
    link.click();
  } catch (e) {
    if (e.response) {
      const { data } = e.response;
      if (data instanceof Blob) {
        // Read file
        const file = await fileReader(data);
        // Parse content and retrieve 'data'
        e.response.data = JSON.parse(file);
      }
      matchStatusAndThrowError(e, "Download Annual Summary Report");
    }
    return;
  }
};

export const getAnnualSummaryReports = async (pagination: PaginationObject) => {
  const options = HttpClient.requestConfig();
  try {
    const { data } = await HttpClient.get(
      `${base_path}${getFacilityPath()}/reports/annualSummary`,
      { params: pagination, ...options }
    );
    return data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Get Annual Summary Reports");
    } else {
      throw e;
    }
  }
};

export const testActiveDirectorySettings = async (settings: ActiveDirectorySettingsClient) => {
  const options = HttpClient.requestConfig();
  try {
    const { data } = await HttpClient.post(
      `${paths.activeDirectorySettings}/ping`,
      settings,
      options
    );
    return data.success;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Test Active Directory Settings");
    } else {
      throw e;
    }
  }
};

export const editActiveDirectorySettings = async (
  settings: ActiveDirectorySettingsClient,
  editing: boolean
) => {
  const options = HttpClient.requestConfig();
  try {
    const { data } = editing
      ? await HttpClient.put(paths.activeDirectorySettings, settings, options)
      : await HttpClient.post(paths.activeDirectorySettings, settings, options);
    return data.activeDirectorySettings;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Edit Active Directory Settings");
    } else {
      throw e;
    }
  }
};

export const searchActiveDirectoryUsers = async (query: string, field: string) => {
  const options = HttpClient.requestConfig();
  try {
    const { data } = await HttpClient.post(
      paths.activeDirectorySearch,
      { query, fields: [field] },
      options
    );
    return data.activeDirectoryUsers;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "Search Active Directory Users");
    } else {
      throw e;
    }
  }
};

export const getAPIforProduction = async (productionId: number) => {
  const options = HttpClient.requestConfig();
  try {
    const { data } = await HttpClient.get(
      `${base_path}${getFacilityPath()}/products/${productionId}/api`,
      options
    );
    return data;
  } catch (e) {
    if (e.response) {
      matchStatusAndThrowError(e, "No API found for given production");
    } else {
      throw e;
    }
  }
};

export const PermissionsObject = {
  User: {
    Add: false,
    Archive: false,
    Modify: false,
    Get: false
  },
  UserRole: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  Equipment: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false,
    Archive: false
  },
  EquipmentGroup: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false,
    Set: false
  },
  Product: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false,
    Archive: false,
    Set: false
  },
  ProductGroup: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  ProductType: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  MACFormula: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  RPNFormula: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  RPNCategory: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  CEReport: {
    Generate: false,
    Get: false,
    GeneratePDF: false
  },
  AuditLog: {
    Get: false
  },
  Variable: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  DefaultUnit: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  Master: {
    BulkExport: false,
    BulkImport: false
  },
  SwabLocation: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  ResidueMeasure: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  ApprovalPolicy: {
    Modify: false,
    Get: false,
    Sign: false
  },
  Department: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  CleaningPolicy: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  SelectionCriteria: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  EquipmentWiseCleaningLimit: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  WorstProductOverride: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  EquipmentWiseWorstProduct: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  FacilityCleaningEvaluation: {
    Generate: false,
    Get: false
  },
  ReportDocument: {
    Generate: false,
    Get: false,
    Modify: false
  },
  AnalyticalMethod: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  EquipmentWiseCleaningAgentLimit: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  API: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  Production: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false,
    Dummy: false,
    Set: false,
    Archive: false
  },

  QualificationTask: {
    Get: false,
    Add: false,
    Modify: false
  },
  Facility: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  RiskCalculation: {
    Get: false
  },
  SamplingMethod: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  CleaningProcedure: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  Substance: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  ApiEquipment: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  ApiProduct: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  ApiProduction: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  },
  EquipmentTrain: {
    Add: false,
    Delete: false,
    Modify: false,
    Get: false
  }
};

export const getLicenseDetails = async () => {
  const options = HttpClient.requestConfig();

  try {
    const currentUrl = new URL(window.location.href);
    sessionStorage.setItem(SessionStorageKeys.TOKEN, currentUrl.searchParams.get("token") || "");

    // Set the token in the request headers for subsequent API calls
    axios.defaults.headers.common.token = sessionStorage.getItem(SessionStorageKeys.TOKEN);

    // Remove the token parameter
    currentUrl.searchParams.delete("token");

    // Update the URL without the token parameter
    window.history.replaceState({}, document.title, currentUrl.href);
    const { data } = await HttpClient.get(
      `${base_path}/plans/production/facilities/license`,
      options
    );
    return data;
  } catch (e: any) {
    if (e.response) {
      matchStatusAndThrowError(e, "No API found for given production");
    } else {
      throw e;
    }
  }
};
